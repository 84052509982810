import React from 'react'
import Skills from './Skills'

const About = () => {
  return (
    <div className='about bottom-border screen-l-flex'>
      <div className='about-flex'>
        <h1 className='subtitle'>About Me</h1>
        <p>In my previous role as a business analyst, I worked in a small team managing the software of a data management company. I thoroughly enjoyed the problem-solving element of the job, leaning towards the more technical aspects of the role. Eager to gain a greater grasp on the technical foundations of software, I took up a software engineering course at General Assembly.
          <br/>
          <br/>
          I am now looking forward to working in a passionate team where I can continue to grow and learn from others, and hopefully soon others learn from myself.
        </p>
      </div>
      <Skills />

    </div>
  )
}

export default About
