import React from 'react'

const Interests = () => {
  return (
    <div className="bottom-border">
      <h1 className='subtitle'>Interests</h1>
      <h3 className="">Sports</h3>
      <div className='flex interests margin'>
        <img src="/images/anfield.jpeg" alt='anfield' height='50%' width='100%' className='circle'/>
        <p>I have a wide interest in sports. Growing up I played football, rugby, rowing and tennis. While I still enjoy playing sports occasionally, I now watch more sports than play. I am a long-time follower of football and football statistics (fantasy football is a crutch of mine!) but I also love watching rugby, F1 and tennis.</p>
      </div>
      <br/>
      <br/>
      <h3 className="">Video Games</h3>
      <div className='flex interests margin'>
        <p>As long as I can remember I have had either a keyboard or a controller in my hand. Particularly over lockdown I have spent lots of free-time playing video games online with friends, with a focus on strategy and co-operative games that require tactics and co-ordination as well as personal skill.</p>
        <img src="/images/video-games.jpeg" alt='controllers' height='50%' width='100%' className='circle'/>
      </div>
      <br/>
      <br/>
    </div>
  )
}

export default Interests
