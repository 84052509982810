import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHtml5, faCss3, faJs, faSass, faReact, faNodeJs, faPython, faGithub, faYarn, faNpm } from '@fortawesome/free-brands-svg-icons'
import { Icon } from '@iconify/react'
import postgresqlIcon from '@iconify-icons/cib/postgresql'
import djangoIcon from '@iconify-icons/simple-icons/django'
import insomniaIcon from '@iconify-icons/simple-icons/insomnia'
import expressIcon from '@iconify-icons/simple-icons/express'
import mongodbIcon from '@iconify-icons/cib/mongodb'
import bulmaIcon from '@iconify-icons/mdi/bulma'

const Skills = () => {
  return (
    <div className='skills-flex'>
      <h1 className='subtitle'>Skills</h1>
      <div className="skills">

        <div className="skill">
          <FontAwesomeIcon icon={faHtml5} size="4x"/>
          <p className="skill-name">HTML</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faCss3} size="4x"/>
          <p className="skill-name">CSS</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faJs} size="4x" />
          <p className="skill-name">JavaScript</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faReact} size="4x" />
          <p className="skill-name">React.js</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faSass} size="4x" />
          <p className="skill-name">Sass</p>
        </div>
        <div className="skill">
          <Icon icon={bulmaIcon} color="white" width="60" height="60" />
          <p className="skill-name">Bulma</p>
        </div>
        <div className="skill">
          <Icon icon={mongodbIcon} color="white" width="60" height="60" />
          <p className="skill-name">MongoDB</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faNodeJs} size="4x" />
          <p className="skill-name">Node.js</p>
        </div>
        <div className="skill">
          <Icon icon={expressIcon} color="white" width="60" height="60" />
          <p className="skill-name">Express</p>
        </div>
        <div className="skill">
          <Icon icon={insomniaIcon} color="white" width="60" />
          <p className="skill-name">Insomnia</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faPython} size="4x" />
          <p className="skill-name">Python</p>
        </div>
        <div className="skill">
          <Icon icon={djangoIcon} width="60" height="60" />
          <p className="skill-name">Django</p>
        </div>
        <div className="skill">
          <Icon icon={postgresqlIcon} color="white" width="60" height="60" />
          <p className="skill-name">PostgreSQL</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faGithub} size="4x" />
          <p className="skill-name">GitHub</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faYarn} size="4x" />
          <p className="skill-name">Yarn</p>
        </div>
        <div className="skill">
          <FontAwesomeIcon icon={faNpm} size="4x" />
          <p className="skill-name">Npm</p>
        </div>
      </div>
    </div>
  )
}

export default Skills
