import About from './Components/About'
import Contact from './Components/Contact'
import Experience from './Components/Experience'
import Homepage from './Components/Homepage'
import Interests from './Components/Interests'
import Projects from './Components/Projects'
import Navbar from './Navbar/Navbar'
import React, { useState } from 'react'
import Menu from './Navbar/Menu'

function App() {

  const [display, setDisplay] = useState(false) 
  console.log(display, setDisplay)


  return (
    <>
      <Navbar />
      { display === true &&
        <Menu />
      }
      
      <div id="home">
        <Homepage />
      </div>

      <div id="about">
        <About />
      </div>

      <div id="projects">
        <Projects />
      </div>
      
      <div id="experience">
        <Experience />
      </div>

      <div id="interests">
        <Interests />
      </div>

      <div id="contact">
        <Contact />
      </div>

    </>
  )
}

export default App
