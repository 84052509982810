import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const Contact = () => {
  return (
    <div className="bottom-border">
      <h1 className='subtitle'>Get in Touch</h1>
      <div className="contact-container">
        <a href="mailto:michael_edwards1994@icloud.com">
          <div className="flex-contact">
            <FontAwesomeIcon icon={faEnvelope} />
            <p className="no-margin">michael_edwards1994@icloud.com</p>
          </div>
        </a>

        <a href="https://github.com/MikeEdwards94" target="_blank" rel="noopener noreferrer">
          <div className="flex-contact">
            <FontAwesomeIcon icon={faGithub} />
            <p className="no-margin">github.com/MikeEdwards94</p>
          </div>
        </a>

        <a href="https://www.linkedin.com/in/michael-edwards-dev/" target="_blank" rel="noopener noreferrer">
          <div className="flex-contact">
            <FontAwesomeIcon icon={faLinkedinIn} />
            <p className="no-margin">linkedin.com/in/michael-edwards-dev</p>
          </div>
        </a>

      </div>
    </div>
  )
}

export default Contact
