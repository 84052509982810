import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBars } from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {


  const [isActive, setIsActive] = useState('')
  console.log(isActive)



  return (
    <>
      <div className='navbar flex-row'>
        <a href='#home'>
          <div className='left'>
            <FontAwesomeIcon icon={faHome} size="2x" className='white underline'/>
          </div>
        </a>
        <div className='right'>
          <FontAwesomeIcon icon={faBars} size="2x" className='white underline' onClick={() => setIsActive(!isActive)}/>
        </div>
      </div>
      <div>
        {isActive &&
      <div className='menuscreen'>
        <a href='#home' onClick={() => setIsActive(!isActive)}>
          <h1>Home</h1>
        </a>
        <a href='#about' onClick={() => setIsActive(!isActive)}>
          <h1>About
          </h1>
        </a>
        <a href='#experience' onClick={() => setIsActive(!isActive)}>
          <h1>Experience
          </h1>
        </a>
        <a href='#projects' onClick={() => setIsActive(!isActive)}>
          <h1>Projects
          </h1>
        </a>
        <a href='#interests' onClick={() => setIsActive(!isActive)}>
          <h1>Interests
          </h1>
        </a>
        <a href='#contact' onClick={() => setIsActive(!isActive)}>
          <h1>Contact Info
          </h1>
        </a>
      </div>
        }
      </div>
    </>
  )
}

export default Navbar
