import React from 'react'

const Experience = () => {
  return (
    <div className="bottom-border">
      <h1 className='center color2 subtitle'>Previous Experience</h1>
      <div className='flex margin'>

        <div className='xp'>
          <div className='xp-header'>
            <img src='/images/36d41c11bee424f7184190d29fe7b137_400x400-removebg-preview.png' alt='logo' height='36' width='36' />
            <h2 className='xp-title'>Hellios Information</h2>
          </div>
          <h2>Business Analyst</h2>
          <h3>Nov 2018 - Apr 2020</h3>
          <p>Helped manage day to day running of softwares critical for running of business. This included handling tech support tickets, fixing bugs using a config, working with an external software development team to plan and release new versions of software.</p>
        </div>

        <div className='xp'>
          <div className='xp-header'>
            <img src='/images/favicon.ico' alt='logo' height='36' width='36' />
            <h2 className='xp-title'>General Assembly</h2>
          </div>

          <h2>Software Engineering Immersive</h2>
          <h3>Feb 2021 – Apr 2021</h3>
          <p>12-week course done remotely over Zoom and Slack to learn core skills of software engineering. Involved solo and as well as group labs and projects, pair-coding, daily stand-ups, lectures on material and homework. The course also required four projects (solo and group) forming the start of my software engineering portfolio</p>
        </div>


      </div>
    </div>
  )
}

export default Experience
