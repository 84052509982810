import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

const Projects = () => {
  return (
    <div className="bottom-border">
      <h1 className='subtitle'>Projects</h1>
      <div>
        <h2>Space Invaders</h2>
        <p>After four weeks of learning HTML, CSS and vanilla JavaScript, I was able to build a working version of Space Invaders in the style of Doom, helping me to feel comfortable with the skills learnt so far.</p>
        <div className='project-image'>
          <img src='/images/Doom.png' alt='project' className='project-image' />
        </div>
        <div className='project-center'>
          <a href='https://github.com/MikeEdwards94/sei-project-one' target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} size="2x" className='project-icon'/>
          </a>
          <a href='https://mikeedwards94.github.io/sei-project-one' target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faPlay} size="2x" className='project-icon'/>
          </a>
        </div>
      </div>
      <div className="bottom-line" />

      <div>
        <h2>Tipple your Fancy</h2>
        <p>For this project, my partner and I were able to produce a cocktail React application using a public API, with a Bulma framework. We pair-coded throughout the entire project and found it very beneficial working through problems by doing so.</p>
        <div className='project-image'>
          <img src='/images/Tipple.png' alt='project' className='project-image' />
        </div>
        <div className='project-center'>
          <a href='https://github.com/MikeEdwards94/Sei-Project-Two' target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} size="2x" className='project-icon'/>
          </a>
          <a href='https://tipple-your-fancy-mikeedwards.netlify.app' target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faPlay} size="2x" className='project-icon'/>
          </a>
        </div>
      </div>
      <div className="bottom-line" />

      <div>
        <h2>The Natural Wanderer</h2>
        <p>This was a full-stack application based on National Parks using an Express API to serve data from a Mongo database, while using React to construct a front-end with CRUD functionality and Semantic UI as a framework. As a group we worked collaboratively across the project as we were all keen to contribute to all components of the website.</p>
        <div className='project-image'>
          <img src='/images/Wanderer.jpg' alt='project' className='project-image' />
        </div>
        <div className='project-center'>
          <a href='https://github.com/MikeEdwards94/SEI-Project-Three' target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} size="2x" className='project-icon'/>
          </a>
          <a href='https://natural-wanderer.netlify.app' target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faPlay} size="2x" className='project-icon'/>
          </a>
        </div>
      </div>
      <div className="bottom-line" />

      <div>
        <h2>NightOwl</h2>
        <p>This was a full-stack application utilising a Django Rest Framework to serve data from a Postgres database with Python in the backend and a front-end built with React. This project was based on finding bars and clubs in Cardiff, ongoing events and any deals they might have. Despite enjoying working in teams, I decided to go solo for this project as I wanted to leave the course confident in everything I had learnt.</p>
        <div className='project-image'>
          <img src='/images/nightowl.png' alt='project' className='project-image' />
        </div>
        <div className='project-center'>
          <div className='margin-auto'>
            <a href='https://github.com/MikeEdwards94/SEI-Project-4' target='_blank' rel="noopener noreferrer" className='margin-auto'>
              <FontAwesomeIcon icon={faGithub} size="2x" className='project-icon'/>
            </a>
          </div>
          <a href='https://night-owl-me.herokuapp.com/' target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon={faPlay} size="2x" className='project-icon'/>
          </a>
        </div>
      </div>

    </div>
  )
}

export default Projects
