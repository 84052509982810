import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const Homepage = () => {
  return (
    <div className="home-background bottom-border">
      <div className='flex-column'>
        <h1 className='home-header'>Michael Edwards</h1>
        <h2 className='home-subheader'>Developer</h2>
      </div>

      <div className='flex-scroll'>
        <h2>Scroll</h2>
        <FontAwesomeIcon icon={faAngleDown} size="4x"/>
      </div>
    </div>
  )
}

export default Homepage